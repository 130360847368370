import React from 'react';
import Cookies from "js-cookie"
import { Box, Spinner, Text, Anchor, Grid, Heading, Footer } from 'grommet';
import { FieldSet, Field as FormField, Label, Help, Form, TextInput, Select, Button } from "./components/Form"
import OrgSelect from './components/OrgSelect';
import WorkspaceNameInput from './components/WorkspaceNameInput';
import AWSCreds from './components/AWSCreds';
import HCPCreds from './components/HCPCreds';
import VarSets, { CredType } from './components/VarSets';
import Title from './components/Title'
import styled from 'styled-components'

const BlueprintName = styled.span`
  font-family: font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  font-size: 75%;
  font-weight: bold;
`

interface Field {
    name: string
    label: string
    values?: Array<string>
    help?: string
    sensitive?: boolean
}
interface BlueprintProps {
    client?: any
    orgClient?: any
    token?: string
    repo: string | null
    location?: any
    gridArea?: any
}
interface BlueprintState {
    responses: any
    placeholders: any
    variablesets: Array<any>
    ready: boolean
    name?: string
    fields?: Array<Field>
    description?: string
    repository?: string
    website?: string
    logo?: string
    deploying: boolean
    error?: string
    success?: any
    fake: boolean
    sensitiveFields?: Array<string>
}

class BlueprintForm extends React.Component<BlueprintProps, BlueprintState> {
    constructor(props: BlueprintProps) {
        super(props)
        this.state = {
            ready: false,
            placeholders: {},
            variablesets: [],
            deploying: false,
            fake: !!(new URLSearchParams(window.location.search).get('fake')),
            responses: {},
            error: !this.props.repo ? "Need to provide a source repository" : undefined
        }
        this.deploy = this.deploy.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.getBlueprint = this.getBlueprint.bind(this)
        this.generateField = this.generateField.bind(this)
        this.awsCreds = this.awsCreds.bind(this)
        this.storeAWSCreds = this.storeAWSCreds.bind(this)
        this.hcpCreds = this.hcpCreds.bind(this)
        this.storeHCPCreds = this.storeHCPCreds.bind(this)
        this.onVarSet = this.onVarSet.bind(this)
    }
    async getBlueprint() {
        if (this.state.fake) {
            return {
                name: 'Just a placeholder',
                description: "Tiramisu caramels bear claw wafer jelly beans cake. Apple pie marshmallow ice cream cake topping. Bonbon marshmallow biscuit cheesecake toffee tart bonbon. Dragée bonbon lemon drops marshmallow jelly beans gingerbread. Cupcake croissant lemon drops sugar plum. Jelly-o danish lollipop gingerbread dessert croissant. Cupcake apple pie chocolate halvah sweet. Powder toffee bear claw croissant. Halvah lollipop dessert gummies jelly caramels. Muffin chocolate pudding. Chocolate bar toffee cake powder. Biscuit chocolate bar gummi bears cake sweet danish.",
                fields: [
                    { name: 'AWS_ACCESS_KEY_ID', label: 'AWS Access Key' },
                    { name: 'AWS_SECRET_ACCESS_KEY', label: 'AWS Secret Key', sensitive: true },
                    { name: 'aws-region', label: 'AWS Region', values: ['us-east-1', 'us-west-2'] }
                ]
            }
        }
        if (this.props.repo) {
            let response = await this.props.client.get('/blueprint', { params: { repo: this.props.repo } })
            if (response) {
                let blueprint = response.data.blueprint[0]
                let name = Object.keys(blueprint)[0]
                let config = blueprint[name][0]
                let description = config.description
                let fields = config.inputs
                let logo = config.logo
                let website = config.website
                let repository = config.repository
                let data = {
                    name: name,
                    description: description,
                    fields: fields,
                    logo: logo,
                    website: website,
                    repository: repository
                }
                return data
            }
        } 
    }
    async componentDidMount() {
        let that = this
        let promises = []
        promises.push(new Promise((resolve, reject) => {
            if (!that.state.name) {
                that.getBlueprint().then((blueprint: any) => {
                    if (!blueprint) return
                    let sensitive = blueprint.fields.filter((f:Field) => { return f.sensitive }).map((f:Field) => { return f.name })                    
                    that.setState({...blueprint, ...{ sensitiveFields: sensitive }})
                    resolve(true)
                }).catch((err: any) => {
                    console.error(err)
                    if (err.response.data) {
                        console.error(JSON.stringify(err.response.data.message))
                        reject(err.response.data.message)    
                    } else {
                        reject(err.response)
                    }
                })
            } else {
                resolve(true)
            }
        }))
        try {
            await Promise.all(promises)
            this.setState({
                ready: true
            })
        } catch (err) {
            this.setState({
                error: err
            })
        }
    }
    async deploy() {
        this.setState({
            deploying: true,
            error: undefined
        })
        try {
            let varsets = this.state.variablesets.map((v: any) => {
                console.log(v)
                return { id: v.id, global: v.attributes['is-global']}
            })
            let params = {token: this.props.token, 
                varsets: varsets,
                sensitiveFields: this.state.sensitiveFields,
                blueprint: {
                    fields: this.state.fields,
                    repo: this.props.repo
            } }
            if (this.state.responses['AWS_ACCESS_KEY_ID'] && this.state.responses['AWS_SECRET_ACCESS_KEY']) {
                this.storeAWSCreds(this.state.responses['AWS_ACCESS_KEY_ID'], this.state.responses['AWS_SECRET_ACCESS_KEY'])
            }
            if (this.state.responses['HCP_CLIENT_ID'] && this.state.responses['HCP_CLIENT_SECRET']) {
                this.storeHCPCreds(this.state.responses['HCP_CLIENT_ID'], this.state.responses['HCP_CLIENT_SECRET'])
            }
            let response = await this.props.orgClient.post('/workspace', 
                {...this.state.responses, 
                 ...params})
            let workspace = response.data.name
            let workspaceUrl = response.data.url           
            let msg = <Text>New workspace named '<Anchor href={workspaceUrl}>{workspace}</Anchor>` created &amp; plan is running.</Text>
            this.setState({
                deploying: false,
                success: msg
            })
            window.scrollTo(0, 0)
        } catch (err) {
            this.setState({
                deploying: false,
                error: err.message
            })
        }
    }
    handleInputChange(event: any) {
        const target = event.target
        const value = event.value === undefined ? target.value : event.value
        const name = target.name;
        let responses = this.state.responses
        responses[name] = value
        this.setState({
            responses: responses
        })
    }
    renderMessage() {
        if (this.state.error) {
            return <Box
                justify="center"
                align="center"
                pad="small"
                background="red"
                round="small"
                animation="fadeIn"
                fill={true}
            >
                <Text color="white">{this.state.error}</Text>
            </Box>
        } else if (this.state.success) {
            return <Box
            justify="center"
            align="center"
            pad="small"
            background="green"
            round="small"
            animation="fadeIn"
            fill={true}
        >
            <Text color="white">{this.state.success}</Text>
        </Box> 
        }
    }
    generateField(field: Field) {
        let type
        if (field.values) {
            type = <Select options={field.values} name={field.name} onChange={this.handleInputChange} value={this.state.responses[field.name]}/>
        } else if (field.sensitive) {
            type = <TextInput type="password" name={field.name} onChange={this.handleInputChange} placeholder={this.state.placeholders[field.name]} value={this.state.responses[field.name]}/>
        } else {
            type = <TextInput name={field.name} onChange={this.handleInputChange} placeholder={this.state.placeholders[field.name]} value={this.state.responses[field.name]}/>
        }
        return <FieldSet key={field.name}>
            <FormField>
                <Label>
                    {field.label}
                    {field.help ? <Help>{field.help}</Help> : ""}
                    {type}
                </Label>
            </FormField>
        </FieldSet>        
    }
    async onVarSet(varset: any) {
        console.log(varset)
        let varsets = this.state.variablesets
        if (!varsets.includes(varset.varset)) { varsets.push(varset.varset) }
        let placeholders = this.state.placeholders
        let responses = this.state.responses
        varset.varset.variables.forEach((variable: any) => {
            placeholders[variable.attributes.key] = "Set by '" + varset.name + "' variable set"
            responses[variable.attributes.key] = ''
        })
        this.setState({
            placeholders: placeholders,
            variablesets: varsets
        })
    }
    awsCreds(key: string, secret: string) {
        let responses = this.state.responses
        responses['AWS_ACCESS_KEY_ID'] = key
        responses['AWS_SECRET_ACCESS_KEY'] = secret
        this.setState({
            responses: responses
        })
    }
    hcpCreds(client: string, secret: string) {
        let responses = this.state.responses
        responses['HCP_CLIENT_ID'] = client
        responses['HCP_CLIENT_SECRET'] = secret
        this.setState({
            responses: responses
        }) 
    }
    storeAWSCreds(key: string, secret: string) {
        Cookies.set('aws_key', key)
        Cookies.set('aws_secret', secret)
    }
    storeHCPCreds(key: string, secret: string) {
        Cookies.set('hcp_client', key)
        Cookies.set('hcp_secret', secret)
    }
    content() {
        if (this.state.ready) {
            let fieldsets = this.state.fields?.map(field => {
                let clippy
                if (field.name === "AWS_ACCESS_KEY_ID") {
                  clippy = <AWSCreds organization={this.state.responses['tfc-organization']} client={this.props.client} onVarSet={this.onVarSet} onCreds={this.awsCreds} />                
                } else if (field.name === "HCP_CLIENT_ID") {
                  clippy = <HCPCreds organization={this.state.responses['tfc-organization']} client={this.props.client} onVarSet={this.onVarSet} onCreds={this.hcpCreds}/>
                }
                let form = this.generateField(field)
                return [clippy, form]
            })
            return <Form>
                <Heading><BlueprintName>{this.state.name}</BlueprintName></Heading>
                <Text>{this.state.description}</Text>
                <OrgSelect client={this.props.client} onChange={this.handleInputChange} />
                <WorkspaceNameInput onChange={this.handleInputChange} />
                {fieldsets}
                <Footer border={{ side: "top", color: "light-5" }} pad="small" margin={{ "top": "small" }}>
                    <Box width="small">
                        <Button label="Cancel" />
                    </Box>

                    <Box width="small">
                        {this.state.deploying && (<Spinner border={[
                            { side: 'all', color: 'transparent', size: 'xsmall' },
                            { side: 'horizontal', color: 'brand', size: 'xsmall' },
                        ]} />)}
                        {!this.state.deploying && (<Button onClick={this.deploy} primary label="Deploy" active={this.state.deploying} />)}
                    </Box>
                </Footer>
            </Form>
        } else {
            if (this.state.error) return
            return <Spinner border={[
                { side: 'all', color: 'transparent', size: 'medium' },
                { side: 'horizontal', color: 'brand', size: 'medium' },
            ]}
            />
        }
    }
    render() {
        return <Box gridArea={this.props.gridArea}>
            <Title title="Deploy Blueprint"/>
            <Grid columns={['small','flex','small']} gap="none">
                <Box></Box>
                <Box pad={{ top: "small" }}>
                    {this.renderMessage()}            
                    {this.content()}
                </Box>
                <Box></Box>
            </Grid>
        </Box>
    }
}

export default BlueprintForm
