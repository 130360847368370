import React from 'react';
import Creds, { Props, State } from './Creds'
import { Image, Card, Heading } from 'grommet';
import { CredType } from './VarSets';

type AWSProps = Omit<Props, "keyname" | "secretname" | "type">
class AWSCreds extends React.Component<AWSProps, State> {
    constructor(props: AWSProps) {        
        super(props)
        this.state = {
            hasLocalCreds: false,
            hasVarsetCreds: false
        }
        this.syncState = this.syncState.bind(this)
    }
    syncState(state: any) {
        this.setState(state)
    }
    hasCreds() {
        return this.state.hasLocalCreds || this.state.hasVarsetCreds
    }
    render() {
        return <Card key={"aws-creds-"+this.state.organization} pad="medium" gap="small" margin={{vertical: "medium"}} background="light-1" style={{display: (this.hasCreds() ? 'block' : 'none')}}>
            <Heading level="4" margin="none">
		        <Image src="/logo.aws.png" height="30px" margin={{right:"medium"}} />
		        Use existing AWS credentials?
	        </Heading>
            <Creds keyname='aws_key' secretname='aws_secret' type={CredType.AWS} syncState={this.syncState} {...this.props} />
        </Card>
    }
}
export default AWSCreds