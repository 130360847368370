import React, {useState} from 'react';
import { Box, Text, Anchor, Grid, Heading } from 'grommet';
import { Form, FormField, FieldSet, Field, Label, Help, TextInput, Select, Button } from "./Form"
import Cookies from "js-cookie"
import Title from "./Title"

interface LoginProps {
    onComplete: any
    gridArea?: any,
    [x:string]: any
}
const Login: React.FC<LoginProps> = (props) => {
    const label = <Text><Anchor href="https://app.terraform.io/app/settings/tokens">User Token</Anchor></Text>
    const orglabel = <Text><Anchor href="https://app.terraform.io/">Organization Token</Anchor> <em>(Select an Org) &gt; Settings &gt; API Tokens &gt; Organization Token</em></Text>
    const [token, setToken] = useState("")
    const [orgToken, setOrgToken] = useState("")
    
    const login = () => {
        Cookies.set('token', token)
        Cookies.set('orgToken', orgToken)
        console.log('Logged!')        
        props.onComplete()
    }

    return (
        <Box gridArea={props.gridArea}>
            <Title title="Login" />
            <Grid columns={['small','flex','small']} gap="none">
                <Box></Box>
                <Box pad={{ top: "small" }}>
                    <Text>
                        We don't store the credentials below on a server anywhere, they'll exist locally on
                        your machine and be transmitted over HTTPS when required to deal with the API. But&hellip;
                        sharing your Terraform Cloud tokens with random 3rd party services (this isn't on a HashiCorp or Terraform.io domain!)
                        is a definitely bad no good idea and you shouldn't do it. Maybe this is all a big social engineering
                        exercise by our Red Team to see who'd do it? It's not. But then, that's exactly the kind of denial you'd
                        expect them to make... right? 🤔 <br/><br/>

                        See you on the other side?
                    </Text>
                    <Heading level="4">Terraform Cloud Tokens</Heading>
                    <Form onSubmit={props.onComplete}>
                    <FormField name="orgtoken" htmlFor="text-input-id" label={orglabel}>
                        <TextInput id="orgtoken" name="orgtoken" onChange={(event) => setOrgToken(event.target.value)} />
                    </FormField>
                    <FormField name="token" htmlFor="text-input-id" label={label}>
                        <TextInput id="token" name="token" onChange={(event) => setToken(event.target.value)} />
                    </FormField>
                    <Box direction="row" gap="medium">
                        <Button type="submit" primary label="Login" onClick={login}/>
                    </Box>
                    </Form>
                </Box>
                <Box></Box>
            </Grid>
        </Box>
    )
}
export default Login