const customTheme = {
	button: {
	  default: {
	    background: {
	      color: 'rgba(247, 248, 250, 1)' 
	    },
	    color: 'rgba(55, 58, 66, 1)',
	    border: {
	      radius: '2px',
	      width: '1px',
	      color: 'rgba(186, 193, 204, 1)'
	    },
	    font: {
	      weight: '600'
	    },
	  },
	  hover: {
	    color: 'rgba(31, 33, 36, 1)',
	    background: {
	      color: 'white'
	    },
	    border: {
	      color: 'rgba(98, 104, 115, 1)'
	    },
	    primary: {
	      color: 'white',
	      background: {
		color: 'rgba(141, 131, 237, 1)'
	      },
	      border: {
		color: 'rgba(8, 39, 102, 1)'
	      }
	    }      
	  },
	  active: {
	    color: 'rgba(31, 33, 36, 1)',
	    background: {
	      color: 'rgba(220, 224, 230, 1)'
	    },
	    border: {
	      color: 'rgba(98, 104, 115, 1)'
	    },
	    primary: {
	      color: 'white',
	      background: {
		color: 'rgba(59, 50, 146, 1)'
	      },
	      border: {
		color: 'rgba(8, 39, 102, 1)'
	      }
	    }
	  },
	  primary: {
	    background: {
	      color: 'rgba(92, 78, 229, 1)'
	    },
	    border: {
	      radius: '2px',
	      width: '1px',
	      color: 'rgba(8, 39, 102, 1)'
	    },
	    color: 'white',
	    font: {
	      weight: '600'
	    },
	    hover: {
	      
	    }
	  }
	},
	global: {
	  font: {
	    //family: 'gilmer-web, Gilmer, Geneva, Tahoma, Helvetica, Verdana, sans-serif',
	    family: 'metro-web, Metro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
	    size: '16px'
	  },
	  focus: {
	    border: {
	      color: 'rgba(21, 99, 255, 1)',
	    },
	    shadow: {
	      color: 'transparent',
	    }
	  },
	  hover: {
	  },
	  active: {
	  },
      
	  colors: {
	    terraform: 'rgba(141, 131, 237, 1)',
	    brand: 'rgba(141, 131, 237, 1)',
	    error: 'rgba(219, 125, 136, 1)',
	    notice: 'rgba(251, 217, 94, 1)',
	    success: 'rgba(122, 204, 129, 1)',
      
	    button: 'rgba(92, 78, 229, 1)',
      
	    'accent-1': '#6FFFB0',
	    'accent-2': '#7FFFB0',
	    'accent-3': '#8FFFB0',
	    'accent-4': '#9FFFB0',
	    'neutral-1': '#10873D',
	    'neutral-2': '#20873D',
	    'neutral-3': '#30873D',
	    'neutral-4': '#40873D',
	    // Setting new colors
	    blue: '#00C8FF',
	    green: '#17EBA0',
	    teal: '#82FFF2',
	    purple: '#F740FF',
	    red: 'rgba(219, 125, 136, 1)',
      
      
	    orange: '#FFBC44',
	    yellow: '#FFEB59',
	    // you can also point to existing grommet colors
	    brightGreen: 'accent-1',
	    deepGreen: 'neutral-2',
	    // Changing default text color,
	    // all colors could be either a string or a dark and light object
	    text: {
	      dark: 'rgba(111, 118, 130, 1)',
	      light: 'rgba(111, 118, 130, 1)',
	    },
	  },
	},
      
      };
      
export default customTheme