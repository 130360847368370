import React from 'react';
import { Text, Button } from 'grommet';
import VarSets, { CredType } from './VarSets';
import styled from 'styled-components';
import Cookies from "js-cookie"

const Key = styled.span`
    display: inline-block;
    margin: 0 0.5em;
    border: 1px solid rgba(0, 0 , 0, 0.5);
    border-radius: 8px;
    padding: 2px 4px;
    font-size: 90%;
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
`


interface Props {
    onCreds: any
    onVarSet?: any
    type: CredType
    keyname: string
    secretname: string
    client: any
    organization?: string
    syncState?: Function
}
interface State {
    hasLocalCreds: boolean
    hasVarsetCreds: boolean
    key?: string
    secret?: string
    organization?: string
}

class Creds extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        let key = Cookies.get(this.props?.keyname || '') 
        let secret = Cookies.get(this.props?.secretname || '')
        let hasCreds = key && typeof key != 'undefined' && secret && typeof secret != 'undefined' ? true : false
        this.state = {
            hasLocalCreds: hasCreds,
            hasVarsetCreds: false,
            organization: this.props.organization,
            key: key,
            secret: secret,
        }
        this.use = this.use.bind(this)
        this.redactedkey = this.redactedkey.bind(this)
        this.varSetSelector = this.varSetSelector.bind(this)
        this.varSetsUpdated = this.varSetsUpdated.bind(this)
        this.varSetSelected = this.varSetSelected.bind(this)
        this.syncState = this.syncState.bind(this)
    }
    syncState() {
        if (this.props.syncState) { this.props.syncState(this.state) }
    }
    hasCreds() {
        return this.state.hasLocalCreds || this.state.hasVarsetCreds
    }
    redactedkey() {
        let start = this.state.key?.substr(0, 4)
        let end = this.state.key?.substr(this.state.key?.length - 4, 4)
        return start + "..." + end
    }
    varSetSelector() {
        return <VarSets key={`varset-${this.props.type.toString}-${this.state.organization}`} organization={this.state.organization} type={this.props.type} onSelect={this.varSetSelected} onUpdate={this.varSetsUpdated} client={this.props.client}/>
    }
    async varSetSelected(varset: any) {
        if (this.props.onVarSet) {
            this.props.onVarSet(varset)
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.organization != this.props.organization) {
            this.setState({
                organization: this.props.organization
            })    
            this.syncState()
        }
    }
    async varSetsUpdated(varsets: any) {
        let hasCreds = varsets.length > 0
        this.setState({
            hasVarsetCreds: hasCreds
        })
        this.syncState()
    }
    use() {
        this.props.onCreds(this.state.key, this.state.secret)
    }
    async componentDidMount() {  
    }
    localCredSelector() {
        return (this.state.hasLocalCreds && <div><Text margin={{vertical: "small"}}>You've previously used the access key
               <Key>{this.redactedkey()}</Key>. 
               Would you like to use it again?
            </Text>
            <Button primary active label="Use these credentials" onClick={this.use}/></div>)
    }
    render() {
        return <div>
            {this.varSetSelector()}
            {this.localCredSelector()}
        </div>
    }
}
export type { Props, State }
export default Creds