import React, { ChangeEventHandler } from 'react';
import { Box, CheckBox } from 'grommet';
import { FieldSet, Field, Label, Select } from './Form'

enum CredType {
    HCP,
    AWS
}
interface Props {
    onChange?: ChangeEventHandler
    organization?: string
    client: any
    type: CredType
    onUpdate?: Function
    onSelect?: Function
}
interface State {
    varsets: Array<any>
    organization: string
    selected?: any
    fake: boolean
}
const Option = React.memo(({ option, selected }: { option: any, selected: any}) => (
  <Box direction="row" gap="small" align="center" pad="xsmall" >
    <CheckBox tabIndex={-1} onChange={() => {}} label={option} checked={selected} /> 
  </Box>
));
class VarSets extends React.Component<Props, State> {
	constructor(props: Props) {
        super(props)
        this.state = {
            organization: this.props.organization || '',
            varsets: [],
            fake: !!(new URLSearchParams(window.location.search).get('fake')),
        }
        this.componentDidMount = this.componentDidMount.bind(this)
        this.getVarsets = this.getVarsets.bind(this)
        this.updateVarsets = this.updateVarsets.bind(this)
        this.setSelected = this.setSelected.bind(this)
        this.getOptions = this.getOptions.bind(this)
    }
    async hasCreds(type: string) {
        return true
    }
    async getVarsets() {
        let organization = this.state.organization
        try {
            let response:any = await this.props.client.get('/varsets', { params: { organization: organization } })
            let varsets = response.data?.map( (vs:any) => {
                if (vs.variables.filter( (v:any) => { return v.attributes.key === "AWS_ACCESS_KEY_ID" }).length > 0) {
                    vs.aws = true
                }
                if (vs.variables.filter( (v:any) => { return v.attributes.key === "HCP_CLIENT_ID" }).length > 0) {
                    vs.hcp = true
                }
                return vs
            })
            
            return varsets
        } catch (err) {
            console.error(err)
            return []
        }
    }
    async updateVarsets() {
        let varsets = await this.getVarsets()
        let selected = this.state.selected
        if (!selected) {
            let opt = this.filterOptions(varsets).find((o:any) => o.global)
            if (opt) { 
                selected = opt.value
            }
        }
        this.setState({
            varsets: varsets,
            selected: selected
        })        
        if (this.props.onUpdate) { this.props.onUpdate(varsets) }
        this.setSelected(selected)
    }
    componentDidUpdate() {
    }
    async componentDidMount() {
        this.updateVarsets()
    }
    filterOptions(varsets: any) {
        let that = this
        let options = varsets.filter((v:any) => {
            return ((this.props.type === CredType.AWS && v.aws) || (this.props.type === CredType.HCP && v.hcp))
        }).map((v:any) => { 
            return { label: v.attributes.name, value: v.attributes.name, global: v.attributes['is-global'] }
        })
        return options
    }
    getOptions() {
        return this.filterOptions(this.state.varsets)        
    }
    async setSelected(option: any) {
        this.setState({
            selected: option
        })
        if (option && this.props.onSelect) { 
            this.props.onSelect({ name: option, varset: this.state.varsets.find((v:any) => {
                return v.attributes.name == option
            })})
        }
    }
    render() {
        return <FieldSet key={"varset-name-"+this.state.organization}>
            {((this.props.organization && (this.getOptions().length > 0)) && (
                <Field>
                    <Label>Select Variable Set</Label>
                    <Select 
                        name="varsets" 
                        options={this.getOptions()} 
                        placeholder="Select a variable set..."
                        labelKey="label"
                        valueKey={{ key: 'value', reduce: true}}
                        value={this.state.selected}
                        dropHeight="medium" 
                        key={"org-varsets-" + this.state.organization}
                        onChange={({ value: nextValue }) => {
                            this.setSelected(nextValue);
                        }} />
                </Field>
            ))}
        </FieldSet>
    }
}
export { CredType } 
export default VarSets