import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from "react-router-dom";
import { Grommet, Box, Text, Grid } from 'grommet';
import axios from 'axios'
import Cookies from "js-cookie"

import Deploy from './Deploy'
import Policy from './Policy'
import Login from './components/Login'
import Header from './components/Header'

import customTheme from './Theme'

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
} & RouteProps;

const ProtectedRoute = ({isAuthenticated, authenticationPath, ...routeProps}: ProtectedRouteProps) => {
  if(isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    let currentLocation = window.location.pathname + window.location.search
    return <Redirect to={{ pathname: authenticationPath, state: { referrer: currentLocation } }} />;
  }
};
interface AppProps {

}
interface AppState {
  token?: string,
  orgToken?: string,
  loggedIn: boolean,
  client?: any,
  orgclient?: any,
  redirect?: string
}
class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)
    let loggedIn = !!(new URLSearchParams(window.location.search).get('fake'))
    let state = {
      loggedIn: loggedIn
    }
    if (['/login','/logout'].includes(window.location.pathname)) {
      Cookies.remove('token')       
    }
    this.state = state
    this.loginHandler = this.loginHandler.bind(this)
    this.client = this.client.bind(this)
    this.orgclient = this.orgclient.bind(this)
    this.login = this.login.bind(this)
    this.userToken = this.userToken.bind(this)
    this.apiUrl = this.apiUrl.bind(this)
  }

  componentDidMount() {
    this.login()
  }
  login() {
    let token = Cookies.get('token')
    let orgToken = Cookies.get('orgToken')
    let loggedIn = !!(token && orgToken)

    if (loggedIn) {
      this.setState({
        token: token,
        orgToken: orgToken,
        loggedIn: loggedIn,
        redirect: '/deploy',
        client: this.client()
      })
    }
  }
  apiUrl() {
    if (window.location.hostname === "lab.gln.io") return "https://lab-api.gln.io/api"
    return "http://localhost:5000/api" 
  }
  client() {
    let token = Cookies.get('token')
    return axios.create({
      baseURL: this.apiUrl(),
      timeout: 10000,
      headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }
    })
  }
  userToken() {
    return Cookies.get('token')
  }
  orgclient() {
    let token = Cookies.get('orgToken')
    return axios.create({
      baseURL: this.apiUrl(),
      timeout: 10000,
      headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }
    })    
  }
  loginHandler(props:any) {
    return <Login onComplete={this.login} gridArea="main" {...props} />
  }
  loginRedirect() {
    return <Redirect to="/" />
  }
  render() {
    return <Router><Grommet full theme={customTheme}>
      <Grid        
        rows={['xxsmall', 'flex', 'xxsmall']}
        areas={[
          ['header'],
          ['main'],
          ['footer']
        ]}
        gap="none"
      >
        <Header gridArea="header"/>
        {(this.state.token) && (<Switch>
          <ProtectedRoute
            isAuthenticated={this.state.loggedIn}
            authenticationPath="/login"
            path="/deploy"
            render={(props: any) => (
              <Deploy gridArea="main" repo={(new URLSearchParams(props.location.search).get('repo'))} orgClient={this.orgclient()} client={this.client()} token={this.userToken()} />
            )}
          />
          <ProtectedRoute
            isAuthenticated={this.state.loggedIn}
            authenticationPath="/login"
            path="/policy"
            render={(props: any) => (
              <Policy gridArea="main" repo={(new URLSearchParams(props.location.search).get('repo'))} orgClient={this.orgclient()} client={this.client()} token={this.userToken()} />
            )}
          />
          <Route path="/login" component={this.loginHandler} />
          <Route path="/">
            {this.state.redirect ? <Redirect to={this.state.redirect} /> : <Redirect to={"/deploy"} />}
          </Route>
        </Switch>)
        }
        {(!this.state.token) && this.loginHandler({})}
        <Box background="white" gridArea="footer" alignContent="center" pad="large">
          <Box background="white" alignContent="end" border="top" pad={{vertical: "medium"}}>
            <Text alignSelf="end" size="14px">&copy;2021 HashiCorp, Inc. All rights reserved</Text>
          </Box>
        </Box>
      </Grid>
    </Grommet></Router>
  }
}
export default App;
