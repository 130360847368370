import React, { ChangeEventHandler } from 'react';
import { Box, CheckBox } from 'grommet';
import { FieldSet, Field, Label, Select } from './Form'

interface WorkspaceSelectProps {
    onChange?: ChangeEventHandler
    organization?: string
    client: any
}
interface State {
	workspaces: Array<any>
    organization: string
    selected?: any
    fake: boolean
}
const Option = React.memo(({ option, selected }: { option: any, selected: any}) => (
  <Box direction="row" gap="small" align="center" pad="xsmall" >
    <CheckBox tabIndex={-1} onChange={() => {}} label={option} checked={selected} /> 
  </Box>
));
class WorkspaceSelect extends React.Component<WorkspaceSelectProps, State> {
	constructor(props: WorkspaceSelectProps) {
        super(props)
        this.state = {
            organization: this.props.organization || '',
            workspaces: [],
            selected: [],
            fake: !!(new URLSearchParams(window.location.search).get('fake')),
        }
        this.componentDidMount = this.componentDidMount.bind(this)
        this.getWorkspaces = this.getWorkspaces.bind(this)
        this.updateWorkspaces = this.updateWorkspaces.bind(this)
        this.setSelected = this.setSelected.bind(this)
        this.setOptions = this.setOptions.bind(this)
    }
    async getWorkspaces() {
        if (!this.state.organization) return []
        if (this.state.fake) {
            return [
                { name: 'all workspaces (apply globally)', id: '*'},
                { name: 'production-web', id: 'workspace-id-1'}
            ]
        } else {
            let organization = this.state.organization
            let response:any = await this.props.client.get('/workspaces', { params: { organization: organization } })
            return response.data
        }
    }
    async updateWorkspaces() {
        let workspaces = await this.getWorkspaces()
        this.setState({
            workspaces: workspaces
        })
    }
    componentDidUpdate() {
        if (this.props.organization === this.state.organization) return
        if (typeof this.props.organization == 'undefined') return
        this.setState({
            organization: this.props.organization || ''
        })
    }
    componentDidMount() {
        this.updateWorkspaces()
    }
    setOptions() {
        let selected = this.state.selected.map((s: number) => {
            return this.state.workspaces[s]
        })
    }
    setSelected(indexes: any) {
        let selected: Array<number> = []
        selected = indexes
        this.setState({
            selected: selected
        })
    }
    render() {
        return <FieldSet key="workspace-name">
            <Field>
                <Label>Select workspaces</Label>
		        <Select 
                    name="workspaces" 
                    closeOnChange={false}
                    options={this.state.workspaces.map((w:any) => { return w.name })} 
                    placeholder="Select a workspace..."
                    selected={this.state.selected}
                    multiple
                    dropHeight="medium" 
                    key={"org-workspaces-" + this.state.organization}
                    onClose={() =>
                      this.setOptions()
                    }
                    onChange={({ selected: nextSelected }) => {
                      this.setSelected(nextSelected);
                    }}>
                  {(option, index) => (
                      <Option option={option} selected={this.state.selected.indexOf(index) !== -1} />
                  )}
                </Select>
            </Field>
        </FieldSet>
    }
}
export default WorkspaceSelect