import React, { ChangeEventHandler } from 'react';
import { FieldSet, Field, Label, TextInput } from './Form'

interface WorkspaceNameProps {
    onChange?: ChangeEventHandler
}
class WorkspaceNameInput extends React.Component<WorkspaceNameProps> {
    render() {
        return <FieldSet key="workspace-name">
            <Field>
                <Label>New Terraform Cloud Workspace Name</Label>
                <TextInput name="tfc-workspace" onChange={this.props.onChange} />
            </Field>
        </FieldSet>
    }
}
export default WorkspaceNameInput