import React from 'react';
import { Box, Spinner, Text, Anchor, Grid, Heading, Footer } from 'grommet';
import { FieldSet, Field as FormField, Label, Form, TextInput, Button } from "./components/Form"
import OrgSelect from './components/OrgSelect';
import WorkspaceSelect from './components/WorkspaceSelect';
import Title from './components/Title'
import styled from 'styled-components'

const PolicyName = styled.span`
  font-family: font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  font-size: 75%;
  font-weight: bold;
`

interface Field {
    name: string
    label: string
    values?: Array<string>
    help?: string
    sensitive?: boolean
}
interface PolicyProps {
    client?: any
    orgClient?: any
    token?: string
    repo: string | null
    location?: any
    gridArea?: any
}
interface PolicyState {
    responses: any
    ready: boolean
    organization?: string
    name?: string
    fields?: Array<Field>
    description?: string
    repository?: string
    website?: string
    logo?: string
    deploying: boolean
    error?: string
    success?: any
    fake: boolean
    sensitiveFields?: Array<string>
    workspaces?: Array<any>
    policies?: Array<string>
    params?: Array<string>
}

class PolicyForm extends React.Component<PolicyProps, PolicyState> {
    constructor(props: PolicyProps) {
        super(props)
        this.state = {
            ready: false,
            deploying: false,
            fake: !!(new URLSearchParams(window.location.search).get('fake')),
            responses: {},
            error: !this.props.repo ? "Need to provide a source repository" : undefined
        }
        this.deploy = this.deploy.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.getPolicy = this.getPolicy.bind(this)
        this.generateField = this.generateField.bind(this)
        this.selectOrg = this.selectOrg.bind(this)
        this.selectedWorkspaces = this.selectedWorkspaces.bind(this)
    }
    async getPolicy() {
	let response
        if (this.state.fake) {
            response = { 
		data: {
			name: 'CIS 7.1: Ensure Stackdriver Logging is set to Enabled on Kubernetes Engine Clusters',
			description: "Stackdriver Logging is part of the Stackdriver suite of products in Google Cloud Platform. It includes storage for logs, a user interface called the Logs Viewer, and an API to manage logs programmatically. Stackdriver Logging lets you have Kubernetes Engine automatically collect, process, and store your container and system logs in a dedicated, persistent datastore. Container logs are collected from your containers. System logs are collected from the cluster's components, such as docker and kubelet. Events are logs about activity in the cluster, such as the scheduling of Pods.",
            policies: [
                "glenngillen/terraform-foundational-policies-library/blob/master/cis/gcp/kubernetes/gcp-cis-7.1-kubernetes-ensure-stackdriver-logging-is-set-to-enabled-on-kubernetes-engine-clusters/gcp-cis-7.1-kubernetes-ensure-stackdriver-logging-is-set-to-enabled-on-kubernetes-engine-clusters.sentinel"
            ],
            params: [
                "maxPageCount"
            ]
		}
            }
        }
        if (this.props.repo) {
        } 
	if (response) return response.data
    }
    async componentDidMount() {
        let policy = await this.getPolicy()
        this.setState({
            ready: true,
            name: policy?.name,
            description: policy?.description,
            policies: policy?.policies,
            params: policy?.params
        })
    }
 
    async deploy() {
        this.setState({
            deploying: true,
            error: undefined
        })
        try {
            let response = await this.props.orgClient.post('/workspace', 
                {...this.state.responses, 
                 ...{token: this.props.token, 
                     sensitiveFields: this.state.sensitiveFields,
                     blueprint: {
                         repo: this.props.repo
                     } }})
            let workspace = response.data.name
            let workspaceUrl = response.data.url           
            let msg = <Text>New workspace named '<Anchor href={workspaceUrl}>{workspace}</Anchor>` created &amp; plan is running.</Text>
            this.setState({
                deploying: false,
                success: msg
            })
            window.scrollTo(0, 0)
        } catch (err) {
            this.setState({
                deploying: false,
                error: err.message
            })
        }
    }
    handleInputChange(event: any) {
        const target = event.target
        const value = event.value === undefined ? target.value : event.value
        const name = target.name;
        let responses = this.state.responses
        responses[name] = value
        this.setState({
            responses: responses
        })
    }
    renderMessage() {
        if (this.state.error) {
            return <Box
                justify="center"
                align="center"
                pad="small"
                background="red"
                round="small"
                animation="fadeIn"
                fill={true}
            >
                <Text color="white">{this.state.error}</Text>
            </Box>
        } else if (this.state.success) {
            return <Box
            justify="center"
            align="center"
            pad="small"
            background="green"
            round="small"
            animation="fadeIn"
            fill={true}
        >
            <Text color="white">{this.state.success}</Text>
        </Box> 
        }
    }
    generateField(field: string) {
        let type = <TextInput name={field} onChange={this.handleInputChange} />
        return <FieldSet key={field}>
            <FormField>
                <Label>
                    {field}
                    {type}
                </Label>
            </FormField>
        </FieldSet>
    }
    selectOrg(event: any) {
        this.setState({
            organization: event.value
        })
    }
    selectedWorkspaces(workspaces: any) {
        this.setState({
            workspaces: workspaces
        })
    }
    content() {
        if (this.state.ready) {
            console.log(this.state)
            let fieldsets = this.state.params?.map(field => {
                return this.generateField(field)
            })
            return <Form>
                <Heading><PolicyName>{this.state.name}</PolicyName></Heading>
                <Text>{this.state.description}</Text>
                <OrgSelect client={this.props.client} onChange={this.selectOrg} />
                <WorkspaceSelect organization={this.state.organization} client={this.props.client} onChange={this.selectedWorkspaces} key={"org-"+this.state.organization}/>
                {fieldsets}
                <Footer border={{ side: "top", color: "light-5" }} pad="small" margin={{ "top": "small" }}>
                    <Box width="small">
                        <Button label="Cancel" />
                    </Box>

                    <Box width="small">
                        {this.state.deploying && (<Spinner border={[
                            { side: 'all', color: 'transparent', size: 'xsmall' },
                            { side: 'horizontal', color: 'brand', size: 'xsmall' },
                        ]} />)}
                        {!this.state.deploying && (<Button onClick={this.deploy} primary label="Deploy" active={this.state.deploying} />)}
                    </Box>
                </Footer>
            </Form>
        } else {
            if (this.state.error) return
            return <Spinner border={[
                { side: 'all', color: 'transparent', size: 'medium' },
                { side: 'horizontal', color: 'brand', size: 'medium' },
            ]}
            />
        }
    }
    render() {
        return <Box gridArea={this.props.gridArea}>
            <Title title="Deploy Policy"/>
            <Grid columns={['small','flex','small']} gap="none">
                <Box></Box>
                <Box pad={{ top: "small" }}>
                    {this.renderMessage()}            
                    {this.content()}
                </Box>
                <Box></Box>
            </Grid>
        </Box>
    }
}

export default PolicyForm
