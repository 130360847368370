import React from 'react';
import { Heading, Image, Grid, Box } from 'grommet';
const Title: React.FC<{ title: string }> = (props) => {
    return (
	<Box background="#f7f8fa" pad={{top: "large", bottom: "small"}}>
		<Grid columns={['small','flex','small']} gap="none">
		<Box></Box>
		<Box>
			<Heading margin="none" color="rgba(142, 150, 163, 1)" size="small" level="2">HashiCorp Product Lab</Heading>
			<Heading margin={{vertical: "small"}} color="black" size="medium" level="1">{props.title}</Heading>
		</Box>
		<Box></Box>
		</Grid>
	</Box>
    )
  }
export default Title