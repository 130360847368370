import React, { useContext } from 'react';
import { ThemeContext, ThemeType, Header, Image, Grid, Box } from 'grommet';
interface Props {
    [x:string]: any
}

const AppHeader: React.FC<Props> = (props) => {
    const theme = useContext<ThemeType>(ThemeContext);
    return (
      <Header {...props} background="linear-gradient(rgba(0, 0, 0, 1), rgba(27, 33, 45, 1))" pad={{ vertical: "left-gutter", horizontal:"left-gutter"}} >
        <Grid columns={['small','flex','small']} gap="none" >
            <Box></Box>
            <Box><Image src="/logo.hashicorp.png" width="34px" height="34px" /></Box>
            <Box></Box>
        </Grid>
      </Header>
    )
  }
export default AppHeader