import React from 'react';
import { Form, FormField, TextInput as TI, Button, Select as SI, Markdown} from 'grommet';
import styled from 'styled-components'

const FieldSet = styled.fieldset`
  border: none;
  margin-top: 1.5em;
  margin-bottom: 1em;
  padding: 0;
`
const Field = styled.label`
  font-weight: bold;
  font-size: 90%;

  button {
    display: block;
  }
`
const Label = styled.div`
`
const Help = styled(Markdown)`
  display: block;
  font-size: 80%;
  font-style: italic;
  font-weight: normal;
`

const TextInput = styled(TI)`
`

export {Form, FieldSet, FormField, Field, Label, Help, TextInput, Button, SI as Select}