import React, { ChangeEventHandler } from 'react';
import {FieldSet, Field, Label, Select } from './Form'

interface OrgSelectProps {
    client: any
    onChange?: ChangeEventHandler
}
interface OrgState {
    organizations: Array<string>
    fake: boolean
}
class OrgSelect extends React.Component<OrgSelectProps, OrgState> {
    constructor(props: OrgSelectProps) {
        super(props)
        this.state = {
            organizations: [],
            fake: !!(new URLSearchParams(window.location.search).get('fake')),
        }
    }
    async componentDidMount() {
        let response
        if (this.state.fake) {
            response = {
                data: [
                    { id: 'example-org-for-demo' },
                    { id: 'acme-widgets' },
                    { id: 'Compu-Global-Hyper-Mega-Net'}
                ]
            }
        } else {
            response = await this.props.client.get('/organizations')
        }
        if (response) {
            this.setState({
                organizations: response.data.map((d: any) => {
                    return d.id
                })
            })
        }    
    }
    render() {
        return <FieldSet key="org-select">
            <Field>
                <Label>Terraform Cloud Organizaiton</Label>
                <Select options={this.state.organizations} name="tfc-organization" onChange={this.props.onChange} />
            </Field>
        </FieldSet>
    }
}
export default OrgSelect